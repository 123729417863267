/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  FormGroup,
  Input,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import Carousel from 'react-bootstrap/Carousel';

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";
import Example from "components/Footers/CustomCarousel.js";
import { MdCall, MdOutlineDashboardCustomize, MdSupportAgent } from "react-icons/md";
import { RiScissorsCutFill } from "react-icons/ri";

// index page sections
import Download from "../IndexSections/Download.js";

class Landing extends React.Component {
  // sendEmail = () => {
  //   window.Email.send({
  //     // SecureToken: "C973D7AD-F097-4B95-91F4-40ABC5567812",
  //     // Host: "smtp.elasticemail.com",
  //     // Username: "harkamal.singh@laitkor.com",
  //     // Password: "9CA5E2B5FF90F52F83C042AE93397B2D8CEE",
  //     // To: 'harkamal.singh@laitkor.com',
  //     From: "harkamal.singh@laitkor.com",
  //     Subject: "This is the subject",
  //     Body: "And this is the body"
  //     // Attachments: [
  //     //   {
  //     //     name: "smtpjs.png",
  //     //     path: "https://networkprogramming.files.wordpress.com/2017/11/smtpjs.png"
  //     //   }
  //     // ]
  //   }).then(
  //     message => alert(message)
  //   );
  // };
  state = {
    firstForm: {
      name: "",
      email: "",
      mobileNumber: "",
      message: ""
    },
    secondForm: {
      name: "",
      email: "",
      mobileNumber: "",
      message: ""
    },
    sending: false,
    success: false,
    error: null,
  };
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    // this.sendEmail()
  }

  // componentDidUpdate() {
  //   console.log("state update", this.state)
  // }

  handleFirstFormChange = (event) => {
    const {name, value} = event.target

    if (name === "mobileNumber") {
      const isValidLength = value.length >= 10 && value.length <= 12;
      
      if (!isValidLength) {
        console.log("Mobile number must be between 10 and 12 digits");
        // Set an error state if needed
        this.setState({
          firstFormMobileNumberError: "Mobile number must be of 10 digits",
        });
      } else {
        this.setState({ firstFormMobileNumberError: "" }); // Clear error if valid
      }
    }

    this.setState(prev => ({firstForm: {...prev.firstForm, [name]: value}}))
  }

  handleSecondFormChange = (event) => {
    const {name, value} = event.target

    if (name === "mobileNumber") {
      const isValidLength = value.length >= 10 && value.length <= 12;
      
      if (!isValidLength) {
        // Optionally, you can set an error message or handle it as needed
        console.log("Mobile number must be between 10 and 12 digits");
        // Set an error state if needed
        this.setState({
          secondFormMobileNumberError: "Mobile number must be of 10 digits",
        });
      } else {
        this.setState({ secondFormMobileNumberError: "" }); // Clear error if valid
      }
    }

    this.setState(prev => ({secondForm: {...prev.secondForm, [name]: value}}))
  }

  handleFirstFormSubmit = (e) => {
    e.preventDefault();
    for (let key in this.state.firstForm) {
      if (this.state.firstForm.hasOwnProperty(key) && this.state.firstForm[key] === "") return;
    }
    // console.log('values:', this.state.firstForm)
    this.sendMail(this.state.firstForm, "firstFormSubmitBtn")

    this.setState({firstForm: {
      name: "",
      email: "",
      mobileNumber: "",
      message: ""
    }})
  }

  handleSecondFormSubmit = (e) => {
    e.preventDefault();
    for (let key in this.state.secondForm) {
      if (this.state.secondForm.hasOwnProperty(key) && this.state.secondForm[key] === "") return;
    }
    // console.log('values:', this.state.secondForm)
    this.sendMail(this.state.secondForm, "secondFormSubmitBtn")

    this.setState({secondForm: {
      name: "",
      email: "",
      mobileNumber: "",
      message: ""
    }})
  }

  async sendMail(data, submitButtonId) {
    this.setState({sending: true})
    document.getElementById(submitButtonId).innerHTML = "Sending..."

    const emailContent = `<h2>Contact Form Submission</h2>
    <p><strong>Name:</strong> ${data.name}</p>
    <p><strong>Email:</strong> ${data.email}</p>
    <p><strong>Mobile Number:</strong> ${data.mobileNumber}</p>
    <p><strong>Message:</strong> ${data.message}</p>`

    try {
      const response = await window.fetch(process.env.REACT_APP_SEND_MAIL_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
           emailContent
        })
      });

      const responseData = await response.json()
      
      if (responseData.status === "error") {
        throw new Error(responseData.error);
      }

      this.setState({ sending: false, success: true });
      document.getElementById(submitButtonId).innerHTML = "Sent Successfully"

      setTimeout(() => {
        document.getElementById(submitButtonId).innerHTML = "Send Message"
      }, 1500)
    } catch (error) {
      // Error Handling
      console.error('Error sending email:', error);
      this.setState({ sending: false, error: 'Failed to send email' });
      document.getElementById(submitButtonId).innerHTML = "Error Ocurred! Couldn't Send"

      setTimeout(() => {
        document.getElementById(submitButtonId).innerHTML = "Send Message"
      }, 1500)
    }
  }

  render() {
    return (
      <>

        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-xl section-shaped pb-150">
              <div className="shape shape-style-1 shape-primary">
                <span /><span /><span /><span /><span /><span /><span /><span /><span />
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row className="align-items-center">
                    <Col lg="7" md="6">
                      <h1 className="display-1 text-white">Supercharge Your Business</h1>
                      <h3 className="display-4 text-white"><span>Custom ERP Solutions That Evolve With You</span></h3>
                      <p className="lead text-white text-center text-md-left">Laitkor leads the change in ERP innovation, delivering tailored solutions that transform business processes and amplify productivity.</p>
                      <div className="btn-wrapper text-center text-md-left">
                        {/*<Button
                          className="btn-icon mb-3 mb-sm-0"
                          color="info"
                          href="https://demos.creative-tim.com/argon-design-system-react/#/documentation/alerts?ref=adsr-landing-page"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-code" />
                          </span>
                          <span className="btn-inner--text">Components</span>
                        </Button>*/}
                        <Button
                          className="btn-white btn-icon mb-5"
                          color="default"
                          href="#contact"
                        >
                          <span className="btn-inner--text">
                            Get consultation
                          </span>
                        </Button>
                      </div>
                    </Col>
                    <Col lg="5" md="6">
                      <Card className="border-radius-md">
                        <CardBody>
                          <Form onSubmit={this.handleFirstFormSubmit}>
                          <FormGroup
                            className={classnames("", {
                              focused: this.state.nameFocused,
                            })}
                          >
                            <h1 className="display-3 mb-3">Let’s connect to help you and your team.</h1>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-user-run" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Your name"
                                type="text"
                                name="name"
                                value={this.state.firstForm.name}
                                onFocus={(e) =>
                                  this.setState({ nameFocused: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ nameFocused: false })
                                }
                                onChange={this.handleFirstFormChange}
                                required
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup
                            className={classnames({
                              focused: this.state.emailFocused,
                            })}
                          >
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-83" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Email address"
                                type="email"
                                name="email"
                                value={this.state.firstForm.email}
                                onFocus={(e) =>
                                  this.setState({ emailFocused: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ emailFocused: false })
                                }
                                onChange={this.handleFirstFormChange}
                                required
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup
                            className={classnames({
                              focused: this.state.mobileNumberFocused,
                            })}
                          >
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <MdCall size={18} />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Mobile Number"
                                type="text"
                                name="mobileNumber"
                                value={this.state.firstForm.mobileNumber}
                                pattern="\d*"
                                title="Only numbers are allowed"
                                onFocus={(e) =>
                                  this.setState({ mobileNumberFocused: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ mobileNumberFocused: false })
                                }
                                onChange={this.handleFirstFormChange}
                                required
                              />
                            </InputGroup>
                            <p style={{color: "red", fontSize: "10px"}}>{this.state.firstFormMobileNumberError}</p>
                          </FormGroup>
                          <FormGroup className="mb-4">
                            <Input
                              cols="80"
                              name="message"
                              value={this.state.firstForm.message}
                              placeholder="Type a message..."
                              rows="4"
                              type="textarea"
                              onChange={this.handleFirstFormChange}
                              required
                            />
                          </FormGroup>
                          <div>
                            <Button
                              block
                              id="firstFormSubmitBtn"
                              className="btn-round"
                              color="default"
                              size="lg"
                              type="submit"
                            >
                              Send Message
                            </Button>
                          </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          {/*<section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Download Argon
                          </h6>
                          <p className="description mt-3">
                            Argon is a great free UI package based on Bootstrap
                            4 that includes the most important components and
                            features.
                          </p>
                          <div>
                            <Badge color="primary" pill className="mr-1">
                              design
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              system
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              creative
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            Build Something
                          </h6>
                          <p className="description mt-3">
                            Argon is a great free UI package based on Bootstrap
                            4 that includes the most important components and
                            features.
                          </p>
                          <div>
                            <Badge color="success" pill className="mr-1">
                              business
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              vision
                            </Badge>
                            <Badge color="success" pill className="mr-1">
                              success
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="success"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Prepare Launch
                          </h6>
                          <p className="description mt-3">
                            Argon is a great free UI package based on Bootstrap
                            4 that includes the most important components and
                            features.
                          </p>
                          <div>
                            <Badge color="warning" pill className="mr-1">
                              marketing
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              product
                            </Badge>
                            <Badge color="warning" pill className="mr-1">
                              launch
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="warning"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>*/}
          <section className="section section-lg">
            <Container>
              <Row className="row-grid">
                <Col md="12"><h3 className="display-2 mb-0 mb-md-5">Our Technological Arsenal Comprises</h3></Col>
                <Col md="4"> 
                  <img
                    alt="..."
                    className="img-fluid floating border-radius-md"
                    src={require("assets/img/theme/Our-Technological-Arsenal-Comprises.jpg")}
                  />
                </Col>
                <Col className="" md="8">
                  <div className="pr-md-5">
                    {/*<div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div>*/}
                    
                    {/*<p>
                      The kit comes with three pre-built pages to help you get
                      started faster. You can change the text and images and
                      you're good to go.
                    </p>*/}
                    <ul className="list-unstyled">
                      <li className="py-2">
                        <div className="d-flex">
                          <div>
                            <h1><i className="ni ni-settings mr-3" /></h1>
                          </div>
                          <div>
                            <h6 className="mb-0">Bespoke ERP Craftsmanship</h6>
                            <p>We engineer ERP platforms using advanced tools like SAP S/4HANA and Oracle NetSuite, ensuring perfect alignment with your operational needs.</p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex">
                          <div>
                            <h1><i className="ni ni-paper-diploma mr-3" /></h1>
                          </div>
                          <div>
                            <h6 className="mb-0">Odoo ERP Mastery</h6>
                            <p>Our specialists leverage Odoo Studio and OCA modules to fine-tune <b>Odoo ERP</b>, harmonizing it with your distinct workflows and objectives.</p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex">
                          <div>
                            <h1><i className="ni ni-delivery-fast mr-3" /></h1>
                          </div>
                          <div>
                            <h6 className="mb-0">Flawless System Fusion</h6>
                            <p>We orchestrate seamless ERP integration using middleware solutions like MuleSoft and Dell Boomi, guaranteeing a frictionless transition.</p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex">
                          <div>
                            <h1><i className="ni ni-trophy mr-3" /></h1>
                          </div>
                          <div>
                            <h6 className="mb-0">obust Analytics Suite</h6>
                            <p>Implementing tools such as Tableau and Power BI, we provide real-time insights and data-driven decision-making capabilities.</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg bg-gradient-dark why-opt">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6" className="d-none">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/ill/ill-2.svg")}
                    />
                  </div>
                </Col>
                <Col md="12">
                  <h3 className="display-2 mb-0 mb-md-5 text-white">Why Opt for Our ERP Services</h3>
                </Col>
                <Col md="6">
                  <Card>
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-compass-04" />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">Proven Methodology</h5>
                          <p className="mb-0">We employ industry-standard frameworks like ASAP and AIM for successful ERP implementations.</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="mt-0 mt-md-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <MdOutlineDashboardCustomize  />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-warning">Customization Excellence</h5>
                          <p className="mb-0">Utilize low-code platforms and microservices architecture for tailor-made ERP solutions.</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card className="mt-0 mt-md-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <RiScissorsCutFill />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-success">Cutting-Edge Technology</h5>
                          <p className="mb-0">Implement AI and machine learning algorithms for predictive analytics and process automation.</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Card className="mt-0 mt-md-5">
                    <CardBody>
                      <div className="d-flex px-3">
                        <div>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <MdSupportAgent  />
                          </div>
                        </div>
                        <div className="pl-4">
                          <h5 className="title text-warning">Comprehensive Support</h5>
                          <p className="mb-0">Offer 24/7 assistance using advanced ticketing systems and proactive monitoring tools.</p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid">
                <Col md={12}><h3 className="display-2 mb-0 mb-md-5">Laitkor's Industry Proficiency</h3></Col>
                <Col md="4" className="order-md-2">
                  <Card className="bg-default shadow border-0 border-radius-md overflow-hidden">
                    <CardImg
                      alt="..."
                      src={require("assets/img/theme/Laitkors-Industry-Proficiency.jpg")}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      {/*<h4 className="display-3 font-weight-bold text-white">
                       Laitkor's Industry Proficiency
                      </h4>
                      <p className="lead text-italic text-white">
                        The Arctic Ocean freezes every winter and much of the
                        sea-ice then thaws every summer, and that process will
                        continue whatever happens.
                      </p>*/}
                    </blockquote>
                  </Card>
                  <div className="text-center mt-3">
                    <Button
                      block
                      className="btn-dark "
                      color="default"
                      size="lg"
                      type="button"
                      href="#contact"
                    >
                      Book your call now
                    </Button>
                  </div>
                </Col>
                <Col md="8" className="order-md-1">
                  <div className="">
                      {/*<div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                        <i className="ni ni-settings" />
                      </div>*/}
                    
                    <ul className="list-unstyled">
                      <li className="py-2">
                        <div className="d-flex">
                          <div>
                            <h1><i className="ni ni-check-bold mr-3" /></h1>
                          </div>
                          <div>
                            <h6 className="mb-0">E-commerce:</h6>
                            <p>Optimize inventory management with advanced forecasting models and AI-driven demand prediction.</p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex">
                          <div>
                            <h1><i className="ni ni-check-bold mr-3" /></h1>
                          </div>
                          <div>
                            <h6 className="mb-0">Logistics:</h6>
                            <p>Enhance supply chain visibility using IoT sensors and blockchain technology for end-to-end tracking.</p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex">
                          <div>
                            <h1><i className="ni ni-check-bold mr-3" /></h1>
                          </div>
                          <div>
                            <h6 className="mb-0">Healthcare:</h6>
                            <p>Streamline patient management systems with cloud-based solutions and machine learning for personalized care pathways.</p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex">
                          <div>
                            <h1><i className="ni ni-check-bold mr-3" /></h1>
                          </div>
                          <div>
                            <h6 className="mb-0">Professional Services:</h6>
                            <p>Improve project management using agile methodologies and resource utilization with AI-powered scheduling tools.</p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex">
                          <div>
                            <h1><i className="ni ni-check-bold mr-3" /></h1>
                          </div>
                          <div>
                            <h6 className="mb-0">Finance: </h6>
                            <p>Elevate financial operations with real-time analytics, automated compliance checks, and AI-driven risk assessment tools.</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-0 pt-md-5">
            <Container>
              <div className="get-consultation">
                <Row className="align-items-center">
                  <Col md={6} className="order-md-2 text-center text-md-left">
                    <h3 className="display-2 text-white">Looking to build impressive front ends?</h3>
                    <Button
                      className="btn-white btn-icon mt-2 mt-sm-3 mb-4 mb-md-0"
                      color="default"
                      href="#contact"
                    >
                      Get consultation
                    </Button>
                  </Col>
                  <Col md={6} className="order-md-1 text-center text-md-left">Get customized front-end development services to create a modern interface for effective user involvement. Contact us to receive a full consultation and project cost estimation.</Col>
                </Row>
              </div>
            </Container>
          </section>
          
          <section className="section section-lg">
            <Container>
              <Row className="row-grid">
                <Col md={12}><h3 className="display-2 mb-0 mb-md-5">Our Flexible Engagement Models</h3></Col>
                <Col md="4">
                  <img
                    alt="..."
                    className="img-fluid floating border-radius-md"
                    src={require("assets/img/theme/Our-Flexible-Engagement-Models.jpg")}
                  />
                </Col>
                <Col md="8">
                  <div className="pr-md-5">
                    {/*<div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div>*/}
                    
                    {/*<p>
                      The kit comes with three pre-built pages to help you get
                      started faster. You can change the text and images and
                      you're good to go.
                    </p>*/}
                    <ul className="list-unstyled">
                      <li className="py-2">
                        <div className="d-flex">
                          <div>
                            <h1><i className="ni ni-check-bold mr-3" /></h1>
                          </div>
                          <div>
                            <h6 className="mb-0">End-to-End Development</h6>
                            <p>Complete ERP solution design, development, and deployment using Agile and DevOps practices.</p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex">
                          <div>
                            <h1><i className="ni ni-check-bold mr-3" /></h1>
                          </div>
                          <div>
                            <h6 className="mb-0">Augmented Team</h6>
                            <p>Extend your in-house capabilities with our skilled ERP specialists who are proficient in the latest ERP technologies.</p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex">
                          <div>
                            <h1><i className="ni ni-check-bold mr-3" /></h1>
                          </div>
                          <div>
                            <h6 className="mb-0">Strategic Advisory</h6>
                            <p>Expert consultation on ERP strategy, selection, and implementation roadmap using industry benchmarks and best practices.</p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex">
                          <div>
                            <h1><i className="ni ni-check-bold mr-3" /></h1>
                          </div>
                          <div>
                            <h6 className="mb-0">Nearshore Collaboration</h6>
                            <p>Cost-effective and high-quality nearshore software development partnership with seamless communication tools.</p>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex">
                          <div>
                            <h1><i className="ni ni-check-bold mr-3" /></h1>
                          </div>
                          <div>
                            <h6 className="mb-0">Diverse Clientele</h6>
                            <p>We offer our services to Startups, Public Entities, and Enterprises, adapting our solutions to meet the specific requirements of each sector.</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          {/*<section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">The amazing Team</h2>
                  <p className="lead text-muted">
                    According to the National Oceanic and Atmospheric
                    Administration, Ted, Scambos, NSIDClead scentist, puts the
                    potentially record maximum.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-1-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Ryan Tompson</span>
                        <small className="h6 text-muted">Web Developer</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-2-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Romina Hadid</span>
                        <small className="h6 text-muted">
                          Marketing Strategist
                        </small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="primary"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-3-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Alexander Smith</span>
                        <small className="h6 text-muted">UI/UX Designer</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="info"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={require("assets/img/theme/team-4-800x800.jpg")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">John Doe</span>
                        <small className="h6 text-muted">Founder and CEO</small>
                      </h5>
                      <div className="mt-3">
                        <Button
                          className="btn-icon-only rounded-circle"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>*/}
          <section className="section section-md bg-gradient-secondary">
            <Container>
              <div className="p-5">
                <Row className="align-items-center text-center">
                  <Col lg="12">
                    <h3 className="display-2 text-default">About Laitkor</h3>
                    <p className="lead text-default mt-3">Laitkor is a distinguished IT service company dedicated to delivering innovative and impactful software 
                    solutions. Our relentless pursuit of quality and dedication to customer satisfaction, combined with our mastery of state-of-the-art ERP 
                    systems, have established Laitkor as a go-to technological ally for companies across the globe.
                    </p>
                    <Button color="default" href="https://www.laitkor.com/about" size="lg">Read More</Button>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
          {/*<section className="section section-lg bg-gradient-light">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Take the Next Step!</h2>
                  <p className="lead text-white">Ready to transform your business with our state-of-the-art ERP services? Reach out to us today to explore how we can propel your organization towards unprecedented efficiency and growth using the custom ERP software, latest tools, and techniques.</p>
                </Col>
              </Row>
            </Container>
            
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>*/}
          <section className="section section-lg section-contact-us" id="contact">
            <Container>
              <Card className="get-consultation p-0 border-radius-md">
                <CardBody className="p-lg-5">
                  <div className="shape shape-style-1 shape-primary">
                    <Row className="justify-content-center">
                      <Col md="7">
                        <h1 className="mb-1 text-white">Want to work with us?</h1>
                        <p className="mt-0">
                          Your project is very important to us.
                        </p>
                        <Form onSubmit={this.handleSecondFormSubmit}>
                          <FormGroup className={classnames("mt-3", { focused: this.state.nameFocused, })}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-user-run" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Your name"
                                type="text"
                                name="name"
                                value={this.state.secondForm.name}
                                onFocus={(e) =>
                                  this.setState({ nameFocused: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ nameFocused: false })
                                }
                                onChange={this.handleSecondFormChange}
                                required
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup className={classnames({ focused: this.state.emailFocused, })}>
                            <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="ni ni-email-83" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Email address"
                                type="email"
                                name="email"
                                value={this.state.secondForm.email}
                                onFocus={(e) =>
                                  this.setState({ emailFocused: true })
                                }
                                onBlur={(e) =>
                                  this.setState({ emailFocused: false })
                                }
                                onChange={this.handleSecondFormChange}
                                required
                              />
                            </InputGroup>
                          </FormGroup>
                          <FormGroup
                              className={classnames({
                                focused: this.state.mobileNumberFocused,
                              })}
                            >
                              <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <MdCall size={18} />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Mobile Number"
                                  type="text"
                                  name="mobileNumber"
                                  value={this.state.secondForm.mobileNumber}
                                  pattern="\d*"
                                  title="Only numbers are allowed"
                                  onFocus={(e) =>
                                    this.setState({ mobileNumberFocused: true })
                                  }
                                  onBlur={(e) =>
                                    this.setState({ mobileNumberFocused: false })
                                  }
                                  onChange={this.handleSecondFormChange}
                                  required
                                />
                              </InputGroup>
                              <p style={{color: "white", fontSize: "10px"}}>{this.state.secondFormMobileNumberError}</p>
                            </FormGroup>
                          <FormGroup className="mb-4">
                            <Input
                              className="form-control-alternative"
                              cols="80"
                              name="message"
                              value={this.state.secondForm.message}
                              placeholder="Type a message..."
                              rows="4"
                              type="textarea"
                              onChange={this.handleSecondFormChange}
                              required
                            />
                          </FormGroup>
                          <div>
                            <Button
                              id="secondFormSubmitBtn"
                              className="btn-white"
                              color="default"
                              size="lg"
                              type="submit"
                            >
                              Send Message
                            </Button>
                          </div>
                        </Form>
                      </Col>
                      <Col md="5">
                      <Example />
                        {/*<Carousel>
                          <Carousel.Item>
                            <Carousel.Caption>
                              <h3>First slide label</h3>
                              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                            <Carousel.Caption>
                              <h3>Second slide label</h3>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </Carousel.Caption>
                          </Carousel.Item>
                          <Carousel.Item>
                            <Carousel.Caption>
                              <h3>Third slide label</h3>
                              <p>
                                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                              </p>
                            </Carousel.Caption>
                          </Carousel.Item>
                        </Carousel>*/}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Container>
          </section>
          {/*<Download />*/}
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Landing;
