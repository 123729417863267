import React, { Component } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

const items = [
  {
    client: 'Dyllon Schraibman',
    country: 'United States',
    desc: "I gave Laitkor, a tough project not even knowing many of the facets of completing it. It evolved constantly and they didn't complain once, going above and beyond my expectations. Laitkor is an expert at what they do and I believe it was divine me finding them. Now my business has IP thats worth something and I have plenty more projects planned ahead with them.",
    src: require("assets/img/icons/us_flag.png")
  },
  {
    client: 'Brian A',
    country: 'United States',
    desc: "Laitkor's team are professionals. They work through any issues and communicate well. Even at one point we sat down in person to discuss the project and move forward. They did a fine job of creating the API interface and test mobile code. Now I can move forward.",
    src: require("assets/img/icons/us_flag.png")
  },
  {
    client: 'Anders R',
    country: 'United States',
    desc: "Laitkor is very willing and able to fix my issues with email deliverability using AWS SES. Laitkor performed a very smooth email migration and necessary DNS update, guiding me on how to utilize all of the service capabilities of AWS and saving me the maximum on unnecessary third-party tools and services. Thank you.",
    src: require("assets/img/icons/us_flag.png")
  },
  {
    client: 'Michael S',
    country: 'United States',
    desc: "This is the second project awarded to Laitkor. Once again, Laitkor has developed and delivered a perfect program. I found the Laitkor team to be very proactive, highly skilled, and exceptionally responsive. I appreciate most, their focused on delivering user-friendly solutions in a timely manner within the project's budget. I look forward to working with Laitkor and his team in the future.",
    src: require("assets/img/icons/us_flag.png")
  }
];

class Example extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
          className="text-center"
        >
          <div className="client-detail mb-3">
            <CarouselCaption className="display-3" captionText={item.client} />
            
            <div className="d-flex justify-content-center">
              <img height="30" className="mr-2" src={item.src} />
              <CarouselCaption captionText={item.country} />
            </div>
          </div>
          <CarouselCaption className="disc" captionText={item.desc} />
        </CarouselItem>
      );
    });

    return (
      <Carousel
        className="contact-reviews pt-4 mt-5 mt-md-0"
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}
      >
        {slides}
        <div className="d-flex controler">
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </div>
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
      </Carousel>
    );
  }
}


export default Example;
